import { BookingPojo } from "src/app/pojo/booking-pojo";

export class DayWiseReportPojo {

    registeredDevoteeCount:number;
    bookedDevoteeCount:number;
    participatedDevoteeCount:number;
    cancelledDevoteeCount:number;
    balanceDevoteeCount:number;
    overAllAmount:number;
    bPojo:BookingPojo[];
}
