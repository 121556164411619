import { LoggedUserDetailsPojo } from './logged-user-details-pojo';

export class ReportReceiverPojo {

    receiverId: number;
    receiverName: string;
    receiverDesignation: string;
    reportSendTime: string;
    mobileNo: string;
    emailId: string;
    autoReportFlag: string;

    loggedUserDetails = new LoggedUserDetailsPojo();

}
