import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BookingPojo } from 'src/app/pojo/booking-pojo';
import { StaticVariable } from 'src/app/globle.class';

@Injectable({
    providedIn: 'root'
})

export class BookingMasterService {

    constructor(private httpclient: HttpClient) { }

    getBookingll(): Observable<BookingPojo[]> {
        const url = StaticVariable.porturl + 'BookingAll/';
        return this.httpclient.get<BookingPojo[]>(url);
    }

    getBookingByParticipateDate(date: string): Observable<BookingPojo[]> {
        const url = StaticVariable.porturl + 'GetBookingByParticipatedDate/' + date;
        return this.httpclient.get<BookingPojo[]>(url);
    }
    getBookingByDate(from: string, to: string): Observable<BookingPojo[]> {
        const url = StaticVariable.porturl + 'GetBookingByDate/' + from + '/' + to;
        return this.httpclient.get<BookingPojo[]>(url);
    }

    getBookingByBookingNoAndUserId(bno: string, uid: number, lid: number): Observable<BookingPojo> {
        const url = StaticVariable.porturl + 'GetBookingAndDuplicateEntryByBookingNoAndUserId/' + bno + '/' + uid + '/' + lid;
        return this.httpclient.get<BookingPojo>(url);
    }
    getBookingByTransactionIdAndUserId(bno: string, uid: number, lid: number): Observable<BookingPojo> {
        const url = StaticVariable.porturl + 'GetBookingAndDuplicateEntryByTransactionIdAndUserId/' + bno + '/' + uid + '/' + lid;
        return this.httpclient.get<BookingPojo>(url);
    }
    updateBooking(arg: BookingPojo, transId: number, locationId: number, userId: number): Observable<any> {
        const url = StaticVariable.porturl + 'BookingUpdateStatusByVerificationTransactionId/' + transId + '/' + locationId + '/' + userId;
        return this.httpclient.put(url, arg);
    }

    updateBookingwithDateandTime(arg: BookingPojo): Observable<any> {
        const url = StaticVariable.porturl + 'BookingUpdate/';
        return this.httpclient.put(url, arg);
    }

    bookingCancel(arg: BookingPojo): Observable<any> {
        const url = StaticVariable.porturl + 'BookingCancel/';
        return this.httpclient.put(url, arg);
    }

    getBookingbybookingno(bookingno: string): Observable<BookingPojo> {
        const url = StaticVariable.porturl + 'GetBookingByBookingNo/' + bookingno;
        return this.httpclient.get<BookingPojo>(url);
    }

}
